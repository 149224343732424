<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList"
			@clearSearch="clearSearch">
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.fans_id" size="small" clearable placeholder="用户ID查询"></el-input>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="fans_id" title="用户信息" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="type" title="类型" align="center" min-width="100px">
				<template v-slot="{ row }">
					{{ types[row.type] }}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="vip_id" title="VIP套餐ID" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="order_id" title="订单ID" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="add_vip_day" title="增加VIP时长" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="create_time" title="时间" align="center" min-width="80px" />
		</Tables>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'vip-record-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '购买记录',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				types: {
					0: '终止',
					1: '普通购买',
					2: '连续包月购买',
					3: '购买电卡赠送',
					4: 'erp后台赠送',
					5: '赠送苏州用户',
				},
				// 用户搜索
				sLoading: false,
				sFans: [],
				// 设备搜索
				s2Loading: false,
				sDevice: [],
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
				detailDialogUserBalance: 0,
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true;
				params = Object.assign(params, this.searchFormData);
				const res = await this.$api.Vip.Record(params)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
				this.refundDialogShow = false
			},
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
